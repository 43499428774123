<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Tilbygg/Nybygg</h2>
			<p>
				Trenger du en ny garasje eller bod? Kanskje du vil utvide med nytt trimrom eller bygge en vinterhage?  
                Ellingsen Byggservice ordner det du trenger av nybygg og tilbygg!
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 500px;
	max-width: 70%;
	padding-top: 2%;
}

</style>
